<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader>
          <h3>Usuarios</h3>
        </CCardHeader>
         <template>
              <CRow>
                <CCol col="3" class="ml-3 mt-1">
                  <router-link to="/usuarios/crear" v-if="$can('usuario.create')">
                    <CButton color="primary">Crear Nuevo Usuario</CButton>
                  </router-link>
                    
                </CCol>
              </CRow>   
         </template>
        <CCardBody>
          <CDataTable
            hover
            striped
            :items="items"
            :fields="fields"
            :items-per-page="10"
            :pagination="{ doubleArrows: false, align: 'center'}"
            column-filter
            @page-change="pageChange"
            
          >
            <!--<template #status="data">
              <td>
                <CBadge :color="getBadge(data.item.status)">
                  {{data.item.status}}
                </CBadge>
              </td>
            </template>-->
            <template #saldo_actual="{item, index}">
                <td>
                   {{creditosConversorMoneda(item.saldo_actual)}}
                </td>
            </template>
            <template #show_details="{item, index}">
                <td class="py-2">
                  <CButton
                    color="primary"
                    variant="outline"
                    square
                    size="sm"
                    @click="toggleDetails(item, index)"
                  >
                    {{Boolean(item._toggled) ? 'Ocultar' : 'Ver'}}
                  </CButton>
                </td>
              </template>
              <template #details="{item}">
                <CCollapse :show="Boolean(item._toggled)" :duration="collapseDuration">
                  <CCardBody>
                    <!--<CMedia :aside-image-props="{ height: 102 }">-->
                      <h4>
                        {{item.name}}
                      </h4>
                      <p class="text-muted">Registrado Desdes: {{ item.created_at != null ? item.created_at.substr(0,10) : ''}}</p>
                      <p class="text-muted">Estado Usuario: 
                        <CBadge :color="getBadge(item.status)">
                            {{item.status == 1 ? 'Activo' : 'Inactivo'}}
                        </CBadge>
                        </p>
                      <router-link :to="'/usuarios/editar/'+item.iduser" v-if="$can('usuario.edit')">
                          <CButton size="sm" color="info" class="">
                            Editar Usuarios
                          </CButton>
                      </router-link>
                      <router-link :to="'/usuarios/impuestos/'+item.iduser" v-if="$can('usuario.impuestos')">
                          <CButton size="sm" color="info" class="">
                            PSE e Impuestos
                          </CButton>
                      </router-link>
                      <router-link :to="'/usuarios/recargas/'+item.iduser" v-if="$can('usuario.recargas')">
                          <CButton size="sm" color="info" class="">
                            Recargas
                          </CButton>
                      </router-link>
                      <router-link :to="'/usuarios/precio/cliente/'+item.iduser" v-if="$can('usuario.precio_cliente')">
                          <CButton size="sm" color="info" class="">
                            Precio Sms Cliente
                          </CButton>
                      </router-link>
                      <!--<router-link :to="'/usuarios/impuestos/'+item.iduser" v-if="$can('usuario.mensajes')">
                          <CButton size="sm" color="info" class="">
                            Ultimos Mensajes
                          </CButton>
                      </router-link>-->
                      <router-link :to="'/usuarios/LogEnvio/'+item.iduser" v-if="$can('usuario.logenvio')">
                          <CButton size="sm" color="info" class="">
                            Logs de Envios
                          </CButton>
                      </router-link>
                      <router-link :to="'/usuarios/logfraude/'+item.iduser" v-if="$can('usuario.logfraude')">
                          <CButton size="sm" color="info">
                            Logs Link Fraude
                          </CButton>
                      </router-link>
                    <!--</CMedia>-->
                  </CCardBody>
                </CCollapse>
              </template>
          </CDataTable>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>


<script>
import EventBus from '../../modules/bus';
export default {
  name: 'EditarUsuario',
  data () {
    return {
      items: [],
      fields: [
        { key: 'name', label: 'Nombre', _classes: 'font-weight-bold' },
        { key: 'email',label:'Email' },
        { key: 'rol',label:'Rol' },
        { key: 'saldo_actual',label:'Creditos' },
        { key: 'celular',label:'Celular' },
        { key: 'show_details',label:'',
          _style: { width: '1%' },
          sorter: false,
          filter: false 
        }
      ],
      activePage: 1,
      collapseDuration: 0
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler (route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page)
        }
      }
    }
  },
  methods: {
    getBadge (status) {
      switch (status) {
        case 1: return 'success'
        case 0: return 'danger'
      }
    },
    rowClicked (item, index) {
      this.$router.push({path: `users/${index + 1}`})
    },
    pageChange (val) {
      this.$router.push({ query: { page: val }})
    },
    getUsuarios(){
        this.$store.dispatch('auth/loading_http','visible');
        this.$http.get('/sms/usuarios').then(response => {
             this.items = response.data;
             this.items.map((item,id) => {  
                if (id%2 == 0) {
                  item['_classes'] = 'table-now'; 
                }
                return item;
              })
             this.items = this.items.map((item, id) => { return {...item, id}})
        }).catch(()=>{
             
        }).finally(() =>this.$store.dispatch('auth/loading_http','hidden'));
    },
    editarUsuario(id) {
      this.$router.push({path: `roles/editar/${id}`}).catch(() => {});
    },
    toggleDetails (item) {
      this.$set(this.items[item.id], '_toggled', !item._toggled)
      this.collapseDuration = 300
      this.$nextTick(() => { this.collapseDuration = 0})
    },
    creditosConversorMoneda(value){
      const formatterDolar = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
      })
      return (formatterDolar.format(value)).replace('$','');
    }
  },
  mounted(){
    setTimeout(() => {
      this.getUsuarios();
    }, 200);
  },
  created: function() {
        EventBus.$on('mostrarNotificacion', (item) => {
          console.log(item);
            //this.comments.push(item)
        });
  }
}
</script>
